var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column justify-center align-center",style:({
    backgroundImage:
      'url(' + require('@/assets/images/' + _vm.background_mobile) + ')',
    height: '100vh',
    width: '100vw',
    // overflow: 'auto',
    backgroundSize: 'cover',
  }),attrs:{"fluid":""}},[(_vm.error !== '')?_c('v-alert',{staticClass:"mt-2",attrs:{"type":"error"}},[_vm._v(_vm._s(_vm.error))]):_vm._e(),(_vm.confirm)?_c('div',{staticClass:"d-flex flex-column align-center"},[_c('h1',{staticClass:"text-center",style:({
        color: _vm.WEBAPPPRO_TEXT_COLOR_1,
        fontSize: _vm.WEBAPPPRO_FONT_SIZE_1,
        marginTop: '10px',
        fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_1,
        fontWeight: 'regular',
        'text-transform': 'none',
      })},[_vm._v(" Votre commande ")]),_c('div',{staticClass:"text-center mt-2",style:({
        color: _vm.WEBAPPPRO_TEXT_COLOR_2,
        fontSize: _vm.WEBAPPPRO_FONT_SIZE_3,
        fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_3,
        'font-weight': 'regular',
        marginLeft: '20px',
        width: '240px',
      })},[_c('p',[_vm._v(" Merci de renseigner votre email pour recevoir les informations sur votre commande ")])]),_c('div',{staticClass:"mb-4 mt-4 text-center ml-0"},[_c('v-text-field',{staticClass:"field",style:({ width: '327px', height: '52px' }),attrs:{"placeholder":"Email","prepend-inner-icon":"mdi-email-outline","solo":"","dense":"","color":_vm.emailColor,"borderColor":_vm.emailColor},on:{"focus":function($event){_vm.emailColor = 'green'},"blur":function($event){_vm.emailColor = ''}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('div',[_c('v-btn',{staticClass:"btn",style:({
          color: _vm.WEBAPPPRO_BUTTON_TEXT_COLOR_1,
          boxShadow: _vm.WEBAPPPRO_BUTTON_SHADOW_1,
          borderRadius: _vm.WEBAPPPRO_BUTTON_BORDER_ROUND,
          'text-transform': 'none',
          marginTop: '10px',
          padding: '25px 100px',
          fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_3,
          fontSize: _vm.WEBAPPPRO_BUTTON_FONT_SIZE,
          'font-weight': 'bold',
          'line-height': '18px',
          'letter-spacing': '0em',
          'text-align': 'center',
          width: '327px',
          height: '52px',
        }),attrs:{"color":_vm.WEBAPPPRO_BUTTON_COLOR_1,"disabled":_vm.uploading,"loading":_vm.uploading},on:{"click":_vm.verifyEmailAndFetchOrderStatus}},[_vm._v(" Soumettre ")])],1)]):_vm._e(),(_vm.message)?_c('div',[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"content-container"},[_c('h1',{staticClass:"text-center",style:({
            color: _vm.WEBAPPPRO_TEXT_COLOR_1,
            fontSize: _vm.WEBAPPPRO_FONT_SIZE_1,
            marginTop: '10px',
            fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_1,
            fontWeight: 'regular',
            textTransform: 'uppercase',
          })},[_vm._v(" Merci ! ")]),_c('v-row',{staticClass:"mt-2",attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('div',{staticClass:"text-center",style:({
                color: _vm.WEBAPPPRO_TEXT_COLOR_2,
                fontSize: _vm.WEBAPPPRO_FONT_SIZE_3,
                fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_3,
                'font-weight': 'regular',
              })},[_c('p',[_vm._v(_vm._s(_vm.orderStatus))])])])],1)],1)])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }