<template>
  <div
    fluid
    :style="{
      backgroundImage:
        'url(' + require('@/assets/images/' + background_desktop) + ')',
      height: '100vh',
      width: '100vw',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
      backgroundSize: 'cover',
    }"
    class="background-container"
  >
    <div :rail="rail" permanent class="accueil-drawer">
      <Accueil></Accueil>
    </div>
    <v-container
      class="container-small d-flex flex-column justify-center align-center"
      style="margin-top: 5%"
    >
      <div>
        <h1
          class="text-center mb-2"
          :style="{
            color: WEBAPPPRO_TEXT_COLOR_4,
            fontFamily: WEBAPPPRO_FONT_FAMILY_2,
            fontSize: WEBAPPPRO_FONT_SIZE_2,
            fontWeight: 700,
            marginTop: '10px',
          }"
        >
          Le QR code est généré !
        </h1>
      </div>
      <div style="margin-top: 10px; margin-bottom: 40px">
        <div
          class="subtitle-1 text-center"
          :style="{
            fontWeight: 400,
            fontFamily: WEBAPPPRO_FONT_FAMILY_2,
            fontSize: WEBAPPPRO_FONT_SIZE_2,
            lineHeight: '20px',
            textAlign: 'center',
            // color: WEBAPPPRO_TEXT_COLOR_2,
          }"
        >
          <p>
            Votre candidat peut maintenant le scanner pour accéder <br />à
            l’application de prise de photo d’identité.
          </p>
        </div>
      </div>
      <div
        class="mx-auto mt-4 mb-4"
        :style="{
          width: '250px',
          height: '250px',
          border: '2px solid ' + WEBAPPPRO_QRCODE_BORDER_COLOR,
          borderRadius: WEBAPPPRO_CARD_BORDER_ROUND,
          boxShadow: WEBAPPPRO_QRCODE_SHADOW,
          padding: '5px',
          backgroundColor: 'white',
        }"
      >
        <v-img :src="getQrCodeFromLocalStorage()"></v-img>
      </div>

      <div class="d-flex flex-column align-center justify-center mt-8">
        <v-btn
          @click="newShoot"
          :color="WEBAPPPRO_BUTTON_COLOR_1"
          :style="{
            color: WEBAPPPRO_BUTTON_TEXT_COLOR_1,
            boxShadow: WEBAPPPRO_BUTTON_SHADOW_1,
            borderRadius: WEBAPPPRO_BUTTON_BORDER_ROUND,
            textTransform: 'none',
            marginBottom: '10px',
            padding: '0px',
            fontFamily: WEBAPPPRO_FONT_FAMILY_3,
            fontSize: WEBAPPPRO_BUTTON_FONT_SIZE,
            fontWeight: 'bold',
            lineHeight: '18px',
            letterSpacing: '0em',
            textAlign: 'center',
            width: '283px',
            height: '57px',
          }"
          >Inscrire un nouveau candidat</v-btn
        >
        <br />
        <a
          href="#"
          @click="goBackAction"
          :style="{
            // color: WEBAPPPRO_TEXT_COLOR_2,
            color: '#000000',
            textDecoration: 'underline',
            fontWeight: '600',
            fontFamily: WEBAPPPRO_FONT_FAMILY_3,
            fontSize: WEBAPPPRO_FONT_SIZE_3,
            lineHeight: '18px',
            letterSpacing: '0em',
            textAlign: 'center',
          }"
          >Retour à l’accueil</a
        >
      </div>
    </v-container>
  </div>
</template>

<script>
import { localStorageService } from '@/services/localStorage.service'
import Accueil from '../../components/Accueil.vue'
export default {
  name: 'GenerateQrCode',
  components: {
    Accueil,
  },
  data() {
    return {
      qrCodeSrc: '',
      language: 'fr',
      styleTitle: localStorageService.getStyle(location.hostname, 'titleStyle'),
      buttonTextValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonTextValidColor',
      ),
      WEBAPPPRO_ICON_COLOR_INFO: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_ICON_COLOR_INFO',
      ),
      WEBAPPPRO_QRCODE_BORDER_COLOR: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_QRCODE_BORDER_COLOR',
      ),
      WEBAPPPRO_COLOR_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_COLOR_1',
      ),
      WEBAPPPRO_QRCODE_SHADOW: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_QRCODE_SHADOW',
      ),
      WEBAPPPRO_COLOR_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_COLOR_2',
      ),
      WEBAPPPRO_COLOR_3: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_COLOR_3',
      ),
      WEBAPPPRO_TEXT_COLOR_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_TEXT_COLOR_1',
      ),
      WEBAPPPRO_TEXT_COLOR_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_TEXT_COLOR_2',
      ),
      WEBAPPPRO_TEXT_COLOR_3: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_TEXT_COLOR_3',
      ),
      WEBAPPPRO_TEXT_COLOR_4: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_TEXT_COLOR_4',
      ),
      WEBAPPPRO_BUTTON_COLOR_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_COLOR_1',
      ),
      WEBAPPPRO_BUTTON_TEXT_COLOR_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_TEXT_COLOR_1',
      ),
      icon: localStorageService.getStyle(location.hostname, 'icon'),
      iconWidth: localStorageService.getStyle(location.hostname, 'iconWidth'),
      WEBAPPPRO_FONT_FAMILY_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_FAMILY_1',
      ),
      WEBAPPPRO_FONT_FAMILY_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_FAMILY_2',
      ),
      WEBAPPPRO_FONT_SIZE_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_SIZE_1',
      ),
      WEBAPPPRO_FONT_SIZE_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_SIZE_2',
      ),
      WEBAPPPRO_FONT_SIZE_3: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_SIZE_3',
      ),
      WEBAPPPRO_FONT_FAMILY_3: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_FAMILY_3',
      ),
      WEBAPPPRO_BUTTON_FONT_SIZE: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_FONT_SIZE',
      ),
      WEBAPPPRO_CARD_BORDER_ROUND: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARD_BORDER_ROUND',
      ),
      WEBAPPPRO_BUTTON_BORDER_ROUND: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_BORDER_ROUND',
      ),
      WEBAPPPRO_BUTTON_SHADOW_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_SHADOW_1',
      ),
      WEBAPPPRO_BUTTON_SHADOW_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_SHADOW_2',
      ),
      background_mobile: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BACKGROUND_MOBILE',
      ),
      WEBAPPPRO_CARD_SHADOW_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARD_SHADOW_1',
      ),
      WEBAPPPRO_CARD_SHADOW_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARD_SHADOW_2',
      ),
      WEBAPPPRO_CARD_SHADOW_3: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARD_SHADOW_3',
      ),
      validatedImages: localStorageService.getValue('validatedImages'),
      addOffer: localStorageService.getValue('addOfferId'),
      WEBAPPPRO_LOGO_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_LOGO_1',
      ),
      WEBAPPPRO_LOGO_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_LOGO_2',
      ),
      WEBAPPPRO_CARTE: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARTE',
      ),
      WEBAPPPRO_CARD_COLOR: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARD_COLOR',
      ),
      WEBAPPPRO_COLOR_1_FILL_ICON: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_COLOR_1_FILL_ICON',
      ),
      WEBAPPPRO_TEXT_COLOR_ETAPES: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_TEXT_COLOR_ETAPES',
      ),
      WEBAPPPRO_BUTTON_COLOR_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_COLOR_2',
      ),
      background_desktop: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BACKGROUND_DESKTOP',
      ),
      pk: process.env.VUE_APP_ENV_STRIPE_PUB_KEY,
    }
  },
  computed: {},
  methods: {
    getQrCodeFromLocalStorage() {
      const qrCode = localStorageService.getValue('qrCode')
      return qrCode ? 'data:image/jpeg;base64,' + qrCode : ''
    },
    newShoot() {
      this.$router.push({
        name: 'New-order-agence',
        params: {
          partner_uid: this.$route.params.partner_uid,
          agence_id: localStorageService.getValue('agenceId'),
          agence_uid: localStorageService.getValue('agenceUuid'),
        },
      })
    },
    goBackAction() {
      this.$router.push({
        name: 'Home-agence',
        params: {
          partner_uid: this.$route.params.partner_uid,
        },
      })
    },
  },
  mounted() {
    // Initialisation du QR code lors du montage
    //this.qrCodeSrc = this.getQrCodeFromLocalStorage()
  },
}
</script>
<!-- <script>
import { localStorageService } from '@/services/localStorage.service'
import Accueil from './Accueil.vue'
export default {
  name: 'GenerateQrCode',
  components: {
    Accueil,
  },
  data() {
    return {
      qrCodeSrc:
        'data:image/jpeg;base64,' + localStorageService.getValue('qrCode'),
      language: 'fr',
      styleTitle: localStorageService.getStyle(location.hostname, 'titleStyle'),
      buttonTextValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonTextValidColor',
      ),
      WEBAPPPRO_ICON_COLOR_INFO: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_ICON_COLOR_INFO',
      ),
      WEBAPPPRO_QRCODE_BORDER_COLOR: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_QRCODE_BORDER_COLOR',
      ),
      WEBAPPPRO_COLOR_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_COLOR_1',
      ),
      WEBAPPPRO_QRCODE_SHADOW: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_QRCODE_SHADOW',
      ),
      WEBAPPPRO_COLOR_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_COLOR_2',
      ),
      WEBAPPPRO_COLOR_3: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_COLOR_3',
      ),
      WEBAPPPRO_TEXT_COLOR_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_TEXT_COLOR_1',
      ),
      WEBAPPPRO_TEXT_COLOR_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_TEXT_COLOR_2',
      ),
      WEBAPPPRO_TEXT_COLOR_3: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_TEXT_COLOR_3',
      ),
      WEBAPPPRO_TEXT_COLOR_4: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_TEXT_COLOR_4',
      ),
      WEBAPPPRO_BUTTON_COLOR_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_COLOR_1',
      ),
      WEBAPPPRO_BUTTON_TEXT_COLOR_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_TEXT_COLOR_1',
      ),
      icon: localStorageService.getStyle(location.hostname, 'icon'),
      iconWidth: localStorageService.getStyle(location.hostname, 'iconWidth'),
      WEBAPPPRO_FONT_FAMILY_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_FAMILY_1',
      ),
      WEBAPPPRO_FONT_FAMILY_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_FAMILY_2',
      ),
      WEBAPPPRO_FONT_SIZE_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_SIZE_1',
      ),
      WEBAPPPRO_FONT_SIZE_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_SIZE_2',
      ),
      WEBAPPPRO_FONT_SIZE_3: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_SIZE_3',
      ),
      WEBAPPPRO_FONT_FAMILY_3: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_FAMILY_3',
      ),
      WEBAPPPRO_BUTTON_FONT_SIZE: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_FONT_SIZE',
      ),
      WEBAPPPRO_CARD_BORDER_ROUND: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARD_BORDER_ROUND',
      ),
      WEBAPPPRO_BUTTON_BORDER_ROUND: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_BORDER_ROUND',
      ),
      WEBAPPPRO_BUTTON_SHADOW_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_SHADOW_1',
      ),
      WEBAPPPRO_BUTTON_SHADOW_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_SHADOW_2',
      ),
      background_mobile: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BACKGROUND_MOBILE',
      ),
      WEBAPPPRO_CARD_SHADOW_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARD_SHADOW_1',
      ),
      WEBAPPPRO_CARD_SHADOW_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARD_SHADOW_2',
      ),
      WEBAPPPRO_CARD_SHADOW_3: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARD_SHADOW_3',
      ),
      validatedImages: localStorageService.getValue('validatedImages'),
      addOffer: localStorageService.getValue('addOfferId'),
      WEBAPPPRO_LOGO_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_LOGO_1',
      ),
      WEBAPPPRO_LOGO_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_LOGO_2',
      ),
      WEBAPPPRO_CARTE: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARTE',
      ),
      WEBAPPPRO_CARD_COLOR: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARD_COLOR',
      ),
      WEBAPPPRO_COLOR_1_FILL_ICON: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_COLOR_1_FILL_ICON',
      ),
      WEBAPPPRO_TEXT_COLOR_ETAPES: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_TEXT_COLOR_ETAPES',
      ),
      WEBAPPPRO_BUTTON_COLOR_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_COLOR_2',
      ),
      background_desktop: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BACKGROUND_DESKTOP',
      ),
      pk: process.env.VUE_APP_ENV_STRIPE_PUB_KEY,
    }
  },
  methods: {
    newShoot() {
      this.$router.push({
        name: 'New-order-agence',
        params: {
          partner_uid: this.$route.params.partner_uid,
          agence_id: localStorageService.getValue('agenceId'),
          agence_uid: localStorageService.getValue('agenceUuid'),
        },
      })
    },
    goBackAction() {
      this.$router.push({
        name: 'Home-agence',
        params: {
          partner_uid: this.$route.params.partner_uid,
        },
      })
    },
  },
  mounted() {
    this.qrCodeSrc =
      'data:image/jpeg;base64,' + localStorageService.getValue('qrCode')
  },
}
</script> -->

<style scoped>
.accueil-drawer {
  width: 300px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: auto;
}
.background-container {
  display: flex;
  flex-direction: column;
}
.custom-data-table .v-data-table__cell {
  font-weight: 500;
  font-size: var(--WEBAPPPRO_FONT_SIZE_3);
}
.focused-border {
  border-color: #47d881 !important;
}
.focused-icon path {
  fill: #47d881 !important;
}
.v-text-field.v-text-field--solo .v-input__prepend-inner {
  align-self: center;
  margin-top: 0;
  padding: 20px;
}
</style>
